var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"position-relative",style:({
    minWidth: _vm.groupListWidth ? `${_vm.groupListWidth}px` : undefined,
    minHeight: '24px',
  })},[_c('v-chip',_vm._g({staticClass:"text-caption font-weight-medium px-2",style:({
      height: '24px',
      visibility: _vm.isEdit ? 'hidden' : undefined,
      ..._vm.groupStyle,
    }),attrs:{"color":!_vm.groupStyle
        ? _vm.hashStringToColor(`${_vm.groupName}_${_vm.groupOwnerEmail}`)
        : undefined,"text-color":!_vm.groupStyle ? 'white' : undefined,"x-small":"","label":""}},
      _vm.canEdit
        ? {
            click: () => _vm.toggleEdit(true),
          }
        : {}
    ),[_vm._v(" "+_vm._s(_vm.groupName)+" "),(_vm.canEdit)?_c('v-icon',{style:({
        marginRight: '-5px',
        marginLeft: '2px',
      }),attrs:{"color":_vm.groupStyle?.color || 'rgba(255, 255, 255, 0.5)',"size":"13"}},[_vm._v(" $edit ")]):_vm._e()],1),(_vm.isEdit)?_c('div',{style:({
      position: 'absolute',
      top: '50%',
      left: _vm.$vuetify.breakpoint.mobile ? undefined : '50%',
      right: _vm.$vuetify.breakpoint.mobile ? '0' : undefined,
      width: '100%',
      transform: _vm.$vuetify.breakpoint.mobile
        ? 'translateY(calc(-50% + 8px))'
        : 'translate3d(-50%, calc(-50% + 8px), 0)',
      maxWidth: _vm.groupListWidth ? `${_vm.groupListWidth}px` : undefined,
    })},[_c('CardGroupList',{attrs:{"value":_vm.groupId,"loading":_vm.creating,"menu-max-width":_vm.groupListWidth,"exclude-fallback-groups":"","x-small":"","autofocus":"","type":"combobox"},on:{"update:validated":_vm.onUpdateValidated,"change":_vm.onChange,"blur":_vm.onBlur}})],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }