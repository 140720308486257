import { Vue, Component, Prop } from "vue-property-decorator";
import { CardGroup as ICardGroup, CardType } from "@/types/card";
import { hashStringToColor } from "@helpers";
import { cardGroupModule } from "@store/namespaces";
import { CardGroupActions } from "@store/modules/card/modules/group/types";

@Component
export default class CardGroup extends Vue {
  @cardGroupModule.Action("createCardGroup")
  private readonly createCardGroupAction!: CardGroupActions["createCardGroup"];

  @Prop({ type: String })
  private readonly cardType?: CardType;
  @Prop({ type: Object })
  private readonly groupStyle?: Record<string, string>;
  @Prop({ type: String }) private readonly groupName?: string;
  @Prop({ type: Number }) private readonly groupId?: number;
  @Prop({ type: String, required: true })
  private readonly groupOwnerEmail!: string;
  @Prop({ type: Boolean, default: true }) private readonly canEdit!: boolean;

  private validated = true;
  private isEdit = false;
  private creating = false;
  private blurTimeoutId = 0;

  private get groupListWidth() {
    return 130;
  }

  private toggleEdit(value = !this.isEdit) {
    this.isEdit = value;
  }

  private onUpdateValidated(value: boolean) {
    this.validated = value;
  }

  private onBlur() {
    window.clearTimeout(this.blurTimeoutId);

    this.blurTimeoutId = window.setTimeout(() => {
      if (this.creating) return;

      this.toggleEdit(false);
    }, 100);
  }

  private async onChange(value: ICardGroup | string | null | -1) {
    if (!value) return;

    if (!this.validated && typeof value === "string") return;

    if (value === -1) {
      this.toggleEdit(false);
      return;
    }

    if (typeof value === "string") {
      this.creating = true;

      try {
        const { id: newCardGroupId } = await this.createCardGroupAction({
          name: value,
        });

        this.$emit("change", newCardGroupId);
        this.toggleEdit(false);
      } finally {
        this.creating = false;
      }
    } else {
      this.toggleEdit(false);
      this.$emit("change", value.id);
    }
  }

  private hashStringToColor(val: string) {
    return hashStringToColor(val);
  }

  private beforeDestroy() {
    window.clearTimeout(this.blurTimeoutId);
  }
}
